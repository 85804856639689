import MobileArticles from '@/components/Articles'
import Layout from '@/components/Layout'
import React, { Component } from 'react'

export default class kontakt extends Component {
    render() {
        return (
            <Layout active={5} title="Kontakt">

                <div className="contact p-margin">
                    <div className="split-50">
                        <div className="contact__div">
                            <div className="v-center">
                                <h1>Kontakta oss</h1>

                                <div className="contact__chunk">
                                    <h3>Telefon</h3>
                                    <p><a href="tel:0704712549">070-4712549</a></p>
                                </div>

                                <div className="contact__chunk">
                                    <h3>Epost</h3>
                                    <p><a href="mailto:paessakonteh@hotmail.com">paessakonteh@hotmail.com</a></p>
                                </div>

                                <div className="contact__chunk">
                                    <h3>Address</h3>
                                    <p><a target="_blank" href="https://www.google.com/maps/place/Bost%C3%A4llsv%C3%A4gen+6,+904+33+Ume%C3%A5/@63.8194198,20.2346481,17z/data=!4m13!1m7!3m6!1s0x467c5b9ddb202d87:0x3027c0dd85c9d40d!2zQm9zdMOkbGxzdsOkZ2VuIDYsIDkwNCAzMyBVbWXDpQ!3b1!8m2!3d63.8193817!4d20.2343676!3m4!1s0x467c5b9ddb202d87:0x3027c0dd85c9d40d!8m2!3d63.8193817!4d20.2343676">KaPaBo, Boställsvägen 6, 904 33 Umeå</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="contact__div contact__image__container">
                            <img className="contact__image" src="/static/images/contact.jpg" />
                        </div>
                    </div>

                </div>

                <MobileArticles amount={3} bottom={false}/>
            </Layout>
        )
    }
}
